import React from 'react';
import {Box} from '@tempo/core';

import Lottie from 'react-lottie-player';
import buildConfig from 'build-config.json';
import NavBarBrand from '../NavBarBrand';
import {LogoColor} from '@components/ShakerLogo';

type HeaderLandingProps = {
	locale: string;
};

const HeaderLanding = ({locale}: HeaderLandingProps) => (
	<>
		<NavBarBrand
			locale={locale}
			background="background.brand.lavender"
			logoColor={LogoColor.BLACK}
		/>
		<Box
			zIndex={0}
			h={['150px', '250px']}
			overflow="hidden"
			bg="background.brand.lavender"
			position="relative"
		>
			<Box position="absolute" left="-5%" h="100%" w="110%" bottom={0}>
				<Lottie
					loop
					path={`${buildConfig.host_assets_ssl}/animations/Shaker_Landingpag.json`}
					play
				/>
			</Box>
		</Box>
	</>
);

export default HeaderLanding;
