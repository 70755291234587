import React from 'react';

import type {
	GetStaticProps,
	GetStaticPropsContext,
	GetStaticPropsResult,
	NextPage,
} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import type {Trackable} from '@customTypes/track';
import useDzrUniqId from '@hooks/useDzrUniqId';
import {useLogEvent} from '@providers/LogEventProvider';
import LandingPage from '@components/LandingPage';

const GroupsPage: NextPage<{locale: string}> = ({locale}) => {
	const dzrUniqId = useDzrUniqId();
	const {eventLogs} = useLogEvent();

	React.useEffect(() => {
		if (dzrUniqId && eventLogs) {
			setTimeout(() => {
				eventLogs.logScreenViewLanding({});
			}, 100);
		}
	}, [dzrUniqId, eventLogs]);

	return <LandingPage locale={locale} />;
};

export const getStaticProps: GetStaticProps = async ({
	locale,
	locales,
}): Promise<
	GetStaticPropsResult<GetStaticPropsContext & {tracking: Trackable}>
> => {
	return {
		props: {
			locale,
			locales,
			tracking: {pagename: 'music-together'},
			...(await serverSideTranslations(locale || '', ['common'])),
		},
	};
};

export default GroupsPage;
