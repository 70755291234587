import React from 'react';
import {Trans, useTranslation} from 'next-i18next';
import {Heading, Button, Text, Flex} from '@tempo/core';
import Container from '@components/Container';
import {useRouter} from 'next/router';
import config from '@modules/config';
import HeaderLanding from '@components/HeaderLanding';

type LandingPageProps = {
	locale: string;
};

const LandingPage: React.FC<LandingPageProps> = ({locale}) => {
	const router = useRouter();
	const {t} = useTranslation();

	const accountWebsiteSsl = config.get('accountWebsiteSsl');

	return (
		<Container p={0} minH="100%" position="relative">
			<HeaderLanding locale={locale} />
			<Flex
				zIndex={2}
				position="relative"
				direction="column"
				alignItems="center"
				p="spacing.xl"
			>
				<Heading
					mb="spacing.l"
					variant="heading.xl"
					color="text.neutral.primary.default"
					textAlign="center"
				>
					{t(
						'musictogetherlanding_title_createplaylistsbasedonfriendstastes_web',
					)}
				</Heading>
				<Text
					variant="body.l.default"
					color="text.neutral.secondary.default"
					mb="spacing.2xl"
					textAlign="center"
				>
					<Trans t={t}>
						{t(
							'musictogetherlanding_text_invitedtocreateplaylistwithfriends_web',
						)}
					</Trans>
				</Text>
				<Button
					size="medium"
					onClick={() =>
						router.push(
							`${accountWebsiteSsl}/${locale}/shaker?path=${router.asPath}`,
						)
					}
				>
					{t('musictogether_action_tryshaker_web')}
				</Button>
			</Flex>
		</Container>
	);
};

export default LandingPage;
